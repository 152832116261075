import React from 'react'
import styled from 'styled-components'
import Book from './Book'

const BookshelfDiv = styled.div`
  margin: 5rem auto;
  text-align: center;
  position: relative;
  max-width: 100%;
  overflow: hidden;
`

const BookGrid = styled.div`
  z-index: 2;
  position: relative;
  transform: translateY(-15px);
`

const BookList = styled.div`
  /* --auto-grid-min-size: 16rem; */
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  grid-gap: 4rem 1rem;
`

export default function Bookshelf() {
  return (
    <BookshelfDiv>
      <BookGrid>
        <BookList>
          <Book title="Artemis Fowl" author="Eoin Colfer" />
          <Book title="Chronicles of Narnia" author="C.S. Lewis" />
          <Book title="Enders Game" author="Orson Scott Card" />
          <Book title="The Name of the Wind" author="Patrick Rothfuss" />
          <Book title="Digital Fortress" author="Dan Brown" />
          <Book title="Blink" author="Malcolm Gladwell" />
          <Book title="The Firm" author="John Grisham" />
        </BookList>
      </BookGrid>
    </BookshelfDiv>
  )
}
