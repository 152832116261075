import React from 'react'
import styled from 'styled-components'
import PropType from 'prop-types'

import BookCover from './BookCover'
import { randomIntFromInterval } from '../utils/rainbowUtils'
import { SmallerShadowAltStyle } from '../css/Styles'

const BookItem = styled.div`
  justify-self: center;
  position: relative;
  text-shadow: ${SmallerShadowAltStyle};
`

const BookTitle = styled.h3`
  font-size: 1.5rem;
`

const BookAuthor = styled.h5`
  font-size: 1rem;
  position: absolute;
  bottom: 2%;
`

export default function Book({ author, title }) {
  const int = randomIntFromInterval(0, 8)

  return (
    <BookItem>
      <BookCover range={int}>
        <BookTitle>{title}</BookTitle>
        <BookAuthor>{author}</BookAuthor>
      </BookCover>
    </BookItem>
  )
}

Book.propTypes = {
  author: PropType.string,
  title: PropType.string
}
