import React from 'react'
import styled from 'styled-components'
import PropType from 'prop-types'

import useRainbow from '../hooks/useRainbow'
import { ReverseShadowStyle } from '../css/Styles'

const BookCover = ({ children, intervalDelay = 1300, range, ...delegated }) => {
  const transitionDelay = intervalDelay * 1.25

  const colors = useRainbow({ intervalDelay })

  const colorKeys = Object.keys(colors)
  const handleZero = range === 0 ? range : range + 0
  const firstColor = colorKeys[handleZero]
  const secondColor = colorKeys[range + 1]
  const thirdColor = colorKeys[range + 2]

  return (
    <Cover
      {...delegated}
      style={{
        ...colors,
        transition: `
          ${firstColor} ${transitionDelay}ms linear,
          ${secondColor} ${transitionDelay}ms linear,
          ${thirdColor} ${transitionDelay}ms linear
        `,
        background: `
          radial-gradient(
            circle at top left,
            var(${thirdColor}),
            var(${secondColor}),
            var(${firstColor})
          )
        `
      }}
    >
      {children}
    </Cover>
  )
}

const Cover = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  width: 200px;
  flex-direction: column;
  box-shadow: ${ReverseShadowStyle};
`

export default BookCover

BookCover.propTypes = {
  children: PropType.node,
  intervalDelay: PropType.number,
  range: PropType.number
}
