import styled from 'styled-components'

const Content = styled.div`
  width: 80%;
  margin: 0 auto;
  transform: skewY(2deg);
  font-size: 1.25rem;
  color: var(--textColor);
  @media (max-width: 768px) {
    font-size: 1rem;
    text-align: justify;
  }
`

export default Content
