import React from 'react'
import Layout from '../components/Layout'
import FunkyTitle from '../components/FunkyTitle'
import Bookshelf from '../components/Bookshelf'
import Section from '../css/Section'
import Content from '../css/ContentDiv'
// import List from '../css/List'

const pageTitle = 'My Bookshelf'

function BookshelfPage() {
  return (
    <>
      <FunkyTitle title={pageTitle} />
      <Section>
        <Content>
          <Bookshelf />
        </Content>
        {/* I enjoy reading &amp; my style of reading involves a bit of hopping around. I don&apos;t think I have read only one book cover to cover in the last year. Here are a list of current books I am reading - */}
        {/* Here are a few of my favorite books -
        <List>
          Artemis Fowl Series
          Roald Dahl
          The Chronicles Of Narnia
          The Hitchhikers Guide to the Galaxy
          Enders Game
          percy jackson
          Davinci code
          The secrets of the immortal nicholas flamel
        </List> */}
      </Section>
    </>
  )
}

// Artemis Fowl, Dan brown, patric rothfuss

BookshelfPage.Layout = Layout
BookshelfPage.pageTitle = pageTitle

export default BookshelfPage
